export default {
    state: {
        currentUser: {
            role: localStorage.getItem('role'),
            dealerId: localStorage.getItem('dealer-id') ? localStorage.getItem('dealer-id') : null
        },
        token: localStorage.getItem('api-token'),
        filters: localStorage.getItem('paron-admin-filters'),
        sortOption: localStorage.getItem('paron-admin-sortOption'),
        sortOrder: localStorage.getItem('paron-admin-sortOrder')
    },
    mutations: {
        setToken(state, value) {
            state.token = value
        },
        setCurrentUser(state, value) {
            state.currentUser = value
            state.loggedIn = true
        },
        setFilters(state, value) {
            state.filters = JSON.stringify(value)
        },
        setSortOption(state, value) {
            state.sortOption = JSON.stringify(value)
        },
        setSortOrder(state, value) {
            state.sortOrder = value
        }
    },
    actions: {
        setSortOrder({ commit }, sortOrder) {
            localStorage.setItem('paron-admin-sortOrder', sortOrder)
            commit('setSortOrder', sortOrder)
        },
        setSortOption({ commit }, sortOption) {
            localStorage.setItem('paron-admin-sortOption', JSON.stringify(sortOption))
            commit('setSortOption', sortOption)
        },
        setFilters({ commit }, filters) {
            localStorage.setItem('paron-admin-filters', JSON.stringify(filters))
            commit('setFilters', filters)
        },
        logout({ dispatch }) {
            dispatch('setCurrentUser', null)
            dispatch('setToken', null)
        },
        login({ dispatch }, resp) {
            if (resp.data.token) {
                dispatch('setToken', resp.data.token)
                dispatch('setCurrentUser', {
                    role: resp.data.role,
                    dealerId: resp.data.dealer
                })
            }
        },
        setToken({ commit }, token) {
            if (token) {
                localStorage.setItem('api-token', token)
                commit('setToken', token)
            } else {
                localStorage.removeItem('api-token')
                commit('setToken', null)
            }
        },
        setCurrentUser({ commit }, currentUser) {
            if (currentUser) {
                if (currentUser.dealerId && currentUser.dealerId !== 'null') {
                    localStorage.setItem('dealer-id', currentUser.dealerId)
                } else {
                    currentUser.dealerId = null
                }
                localStorage.setItem('role', currentUser.role)
                commit('setCurrentUser', currentUser)
            } else {
                localStorage.removeItem('dealer-id')
                localStorage.removeItem('role')
                commit('setCurrentUser', null)
            }
        }
    },
    getters: {
        getSortOption: state => JSON.parse(state.sortOption),
        getSortOrder: state => state.sortOrder,
        getFilters: state => JSON.parse(state.filters),
        getToken: state => state.token,
        currentUser: (state) => {
            if (state.currentUser && !state.currentUser.role && !state.currentUser.dealerId) {
                return null
            }
            return state.currentUser
        },
        isLoggedIn(state) {
            return state.token !== null
        }
    }
}
