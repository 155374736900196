<template>
    <v-navigation-drawer
        color="#213050"
        permanent
        absolute
        expand-on-hover
        style="z-index:10000;"
    >
        <v-list
            dark
        >
            <v-list-item class="px-2">
                <v-img src="@/assets/LOGOPARON 2023.svg" />
            </v-list-item>
        </v-list>
        <v-list
            nav
            dense
            dark
        >
            <v-list-item link @click="logout">
                <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
            <v-divider />
            <div v-for="(route, index) of routes" :key="index">
                <v-list-item v-if="route.roles.length === 0 || route.roles.includes(userRole)" :to="route.to" link>
                    <v-list-item-icon>
                        <v-icon>mdi-{{ route.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ route.title }}</v-list-item-title>
                </v-list-item>
            </div>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import store from '@/store'
    export default {
        name: 'Sidebar',
        data() {
            return {
                routes: [
                    {
                        title: 'Members',
                        to: '/members?page=1&per_page=10',
                        icon: 'account',
                        roles: ['admin', 'dealer']
                    },
                    {
                        title: 'Vouchers',
                        to: '/vouchers?page=1&per_page=10',
                        icon: 'tag',
                        roles: ['admin']
                    },
                    {
                        title: 'Cards',
                        to: '/cards?page=1&per_page=10',
                        icon: 'card-text',
                        roles: ['admin']
                    },
                    {
                        title: 'Offers',
                        to: '/offers?page=1&per_page=10',
                        icon: 'cart',
                        roles: ['admin']
                    },
                    {
                        title: 'Teams',
                        to: '/teams?page=1&per_page=10',
                        icon: 'account-group',
                        roles: ['admin', 'dealer']
                    },
                    {
                        title: 'Partners',
                        to: '/partners?page=1&per_page=10',
                        icon: 'domain',
                        roles: ['admin', 'dealer']
                    },
                    {
                        title: 'Articles',
                        to: '/articles?page=1&per_page=10',
                        icon: 'newspaper-variant-outline',
                        roles: ['admin']
                    },
                    {
                        title: 'Transactions',
                        to: '/transactions?page=1&per_page=10',
                        icon: 'currency-usd',
                        roles: ['admin', 'dealer']
                    },
                    {
                        title: 'Users',
                        to: '/users?page=1&per_page=10',
                        icon: 'account-edit',
                        roles: ['admin']
                    },
                    {
                        title: 'Dealers',
                        to: '/dealers?page=1&per_page=10',
                        icon: 'handshake',
                        roles: ['admin']
                    },
                    {
                        title: 'About Us',
                        to: '/about-us',
                        icon: 'information-outline',
                        roles: ['admin']
                    }

                ]
            }
        },
        computed: {
            userRole() {
                return store.getters.currentUser && store.getters.currentUser.role
            }
        },
        methods: {
            logout() {
                this.$store.dispatch('logout')
                this.$router.push('/login')
            }
        }
    }
</script>

<style scoped>

</style>
